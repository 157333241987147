<template>
  <div class="container">
    <div v-if="loading" class="d-flex justify-content-center my-5">
      <Preloader/>
    </div>
    <div v-else class="my-4">
      <h4 class="text-center mb-4">Студенты НИШ и колледж</h4>

      <div class="table-responsive">
        <table class="table table-bordered table-striped">
          <thead>
          <tr>
            <th>№</th>
            <th>Баркод</th>
            <th>ФИО</th>
            <th>Курс</th>
            <th>ОП</th>
            <th>Группа</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(student, studentIndex) in students" :key="studentIndex">
            <td>{{studentIndex+1}}</td>
            <td>{{student.barcode}}</td>
            <td>
              <router-link :to="`/nis-college-ratings/${student.id}`" target="_blank">
                {{student.last_name}} {{student.first_name}} {{student.middle_name}}
              </router-link>
            </td>
            <td>{{student.study_course}}</td>
            <td>{{student.education_speciality_code}} {{student.education_speciality_name}}</td>
            <td>{{student.name}}</td>
          </tr>
          </tbody>
        </table>
      </div>

    </div>
  </div>
</template>


<script>
  import httpClient from "@/services/http.service"

  export default {
    name: 'NisCollegeStudents',
    data() {
      return {
        loading: true,
        students: []
      }
    },
    computed: {},
    methods: {
      async GET_NIS_COLLEGE_STUDENTS() {
        try {
          const {status, data} = await httpClient.get(`old_ratings/old-student-ratings/get-student-infos`);
          if (status === 200) {
            this.students = data
          }
        } catch (e) {
          this.$error({text: `Произошла ошибка ${JSON.stringify(e.response?.data?.errors)}`})
        }
      },
    },
    async mounted() {
      await this.GET_NIS_COLLEGE_STUDENTS()
      this.loading = false
    },
  }
</script>

<style scoped>

</style>